










































import { Component, Prop } from 'vue-property-decorator';
import { Data } from '@/utils/types/WidgetData';
import { format } from 'date-fns';
import { State } from 'vuex-class';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import DetailPageHeaderComponent from '@/components/page-header/DetailPageHeaderComponent.vue';
import CommunityUserConnection from '@/models/graphql/CommunityUserConnection';
import CommunityUser from '@/models/graphql/CommunityUser';
import ActionButtonType from '@/utils/enums/ActionButtonType';
import ActionButtonGroupComponent from '@/components/action-buttons/ActionButtonGroupComponent.vue';
import ConnectionStatusType from '@/utils/enums/ConnectionStatusType';
import EntityType from '@/utils/enums/EntityType';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { mixins } from 'vue-class-component';

@Component({
  components: { ActionButtonGroupComponent, DetailPageHeaderComponent },
})
/* eslint-disable no-underscore-dangle */
export default class GenericSubPageHeaderComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  baseStoreName = 'GenericPageHeaderWidgetStore';

  @Prop({ required: false, default: null })
  private readonly entityCode!: string;

  @Prop({ required: false, default: null })
  private readonly type!: string;

  @Prop({ required: false, default: null })
  private readonly title!: string;

  @Prop({ required: false, default: null })
  private data!: Record<string, Data>;

  @Prop({ required: false, default: () => [] })
  private readonly actionButtons!: Data[];

  @State
  private dateLocale!: Locale;

  private actionButtonsLocal = this.actionButtons;

  private renderActionButtonsGroup = 0;

  private entityFieldsMap: Record<string, Record<string, string>> = {
    exhibitor: {
      logo: 'logoFileResource',
      banner: 'bannerFileResource',
    },
    product: {
      logo: '',
      banner: 'images',
    },
    largeProduct: {
      logo: '',
      banner: 'images',
    },
    session: {
      logo: '',
      banner: 'bannerFileResource',
    },
    deal: {
      logo: '',
      banner: 'displayFileResource',
    },
    speaker: {
      logo: 'photoFileResource',
      banner: '',
    },
    communityUser: {
      logo: 'pictureFileResource',
      banner: 'bannerFileResource',
    },
    subEdition: {
      logo: 'logoFileResource',
      banner: 'bannerFileResource',
    },
  };

  get detailPageHeaderConfig(): object | null {
    if (this.data) {
      return {
        banners: this.data[this.entityFieldsMap[this.type].banner]
          ? this.data[this.entityFieldsMap[this.type].banner]
          : [],
        // eslint-disable-next-line no-nested-ternary
        logo: this.data[this.entityFieldsMap[this.type].logo]
          ? Array.isArray(this.data[this.entityFieldsMap[this.type].logo])
            ? this.data[this.entityFieldsMap[this.type].logo]
            : this.data[this.entityFieldsMap[this.type].logo]
          : [],
        firstName: this.data.firstName ? this.data.firstName : '',
        lastName: this.data.lastName ? this.data.lastName : '',
        type: this.data.__typename ? this.data.__typename : '',
        entityType: this.data.__typename ? this.data.__typename : '',
        archived: this.data.archived ? this.data.archived : false,
        startTimestamp: this.data.startTimestamp ? this.data.startTimestamp : null,
        endTimestamp: this.data.endTimestamp ? this.data.endTimestamp : null,
      };
    }
    return null;
  }

  get subTitle(): string {
    if (['exhibitor', 'session', 'product', 'deal'].includes(this.type)) {
      return (this.data.name as unknown) as string;
    }
    if (['speaker', 'communityUser'].includes(this.type)) {
      return `${this.data.firstName} ${this.data.lastName}`.trim();
    }
    if (this.type === 'subEdition') {
      let start = null;
      let end = null;
      const result: string[] = [];
      if (this.data.name) {
        result.push((this.data.name as unknown) as string);
      }
      if (this.data.startTime) {
        start = DateTimeHelper.toUTC(new Date((this.data.startTime as unknown) as string));
      }
      if (this.data.endTime) {
        end = DateTimeHelper.toUTC(new Date((this.data.endTime as unknown) as string));
        if (start && end) {
          if (format(end, 'yyyy-MM-dd') === format(start, 'yyyy-MM-dd')) {
            result.push(
              format(start, this.$t('app.date.defaultDateFormat') as string, {
                locale: this.dateLocale,
              }),
            );
          } else if (format(end, 'MM') === format(start, 'MM')) {
            result.push(
              `${format(start, this.$t('app.date.monthDayFull') as string, {
                locale: this.dateLocale,
              })}
              ${this.$t('app.date.dateTimeSeparator')}
               ${format(end, this.$t('app.date.defaultDateFormat') as string, {
    locale: this.dateLocale,
  })}`,
            );
          } else {
            result.push(
              `${format(start, this.$t('app.date.monthDayFull') as string, {
                locale: this.dateLocale,
              })}
              ${this.$t('app.date.dateTimeSeparator')}
              ${format(end, this.$t('app.date.defaultDateFormat') as string, {
    locale: this.dateLocale,
  })}`,
            );
          }
        }
        if (this.data.venue && this.data.venue.name) {
          result.push((this.data.venue.name as unknown) as string);
        }
        return result.join(' | ');
      }
    }
    return '';
  }

  private get uid(): string {
    let r = this.entityCode;
    const matches = this.entityCode.match(/(%[a-zA-Z-_]+%)/gs);
    if (matches) {
      matches.forEach((m) => {
        const prop = m.replaceAll('%', '').trim();
        r = r.replaceAll(m, this.$route.params[prop]);
      });
      return r;
    }
    return this.entityCode;
  }

  private get isProfileVisible(): boolean {
    const typename = this.data.__typename as unknown as string;
    if (typename !== EntityType.USER) return true;

    const user = CommunityUser.hydrate(this.data);
    return user.isProfileVisible;
  }

  private get hasConnection(): boolean {
    const user = CommunityUser.hydrate(this.data);
    return !!user._ourConnection
      && (user._ourConnection.linkState === ConnectionStatusType.ACCEPTED
        || user._ourConnection.linkState === ConnectionStatusType.INVITED);
  }

  created(): void {
    this.setDataConfig();
  }

  private updateConnectActionButton(communityUserConnection: CommunityUserConnection): void {
    const index = this.actionButtonsLocal.findIndex((a) => a.actionType === 'connect');
    if (index > -1 && this.actionButtonsLocal[index].actionResult) {
      this
        .$set(
          this.actionButtonsLocal[index].actionResult as CommunityUser,
          '_ourConnection',
          communityUserConnection,
        );
    }
    this.renderActionButtonsGroup += 1;
  }

  private updateActionButtonProductUrl(elementButtons: Data[]): Data[] {
    elementButtons.forEach((elementButton) => {
      if (elementButton.actionType === ActionButtonType.URL
        || elementButton.actionType === ActionButtonType.LARGE_PRODUCT_URL) {
        const url = this.data.url ? this.data.url.toString() : null;
        if (url) {
          elementButton.route = this.validateURL(url);
        }
        elementButton.label = 'actions.website';
        elementButton.icon = 'far fa-arrow-up-right';
      }
    });
    return elementButtons;
  }

  private validateURL(url: string): string {
    if (url.startsWith('https://') || url.startsWith('http://') || url.startsWith('//')) {
      return url;
    }
    if (url.startsWith('www')) {
      return `//${this.data.url}`;
    }
    return `//${this.data.url}`;
  }
}
