import { render, staticRenderFns } from "./GenericSubPageHeaderComponent.vue?vue&type=template&id=7de04094&scoped=true&"
import script from "./GenericSubPageHeaderComponent.vue?vue&type=script&lang=ts&"
export * from "./GenericSubPageHeaderComponent.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/GenericPageHeaderComponent.scss?vue&type=style&index=0&id=7de04094&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7de04094",
  null
  
)

export default component.exports